

.searchContainerSearchInput {
    width: 97%;
    padding: .5rem .2rem;
    font-size: 0.875rem;
    line-height: 1.25rem ;
    border-radius: 0.375rem;
    outline: none;
    border-top: none;
    border-right: none;
    border-left: none;
    border-width: 2px;
    border-color: #64748b;
    margin: 10px 0;
    box-shadow: rgba(9, 30, 66, 0.25) 0px 1px 1px, rgba(9, 30, 66, 0.13) 0px 0px 1px 1px;
}


.searchContainerSearchInput:focus {
    border-bottom-color: #38ACEC;
}