.deleteBtnContainer {
    background-color: #e2f3fb;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 20px;
    text-align: center;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

.deleteBtns {
    display: flex;
    align-items: center;
    gap: 20px;
    margin: 10px 0;
}

.dltFrMe {
    color: white;
    font-weight: bold;
    background-color: #38ACEC;
    padding: 5px 10px;
    border-radius: 5px;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
    cursor: pointer;
}

.dltFrEveryOne,.cancelBtn {
    background-color: white;
    padding: 5px 10px;
    border-radius: 5px;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
    cursor: pointer;
}
