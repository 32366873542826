.loader {
    width: 280px;
    height: 100px;
    display: block;
    margin: auto;
    position: relative;
    background: #FFF;
    box-sizing: border-box;
  }
  .loader::after {
    content: '';  
    width: calc(100% - 50px);
    height: calc(100% - 30px);
    top: 15px;
    left: 15px;
    position: absolute;
    background-image: linear-gradient(100deg, transparent, rgba(255, 255, 255, 0.5) 50%, transparent 80%), radial-gradient(circle 24px at 24px 24px, #DDD 99%, transparent 0), linear-gradient(#DDD 14px, transparent 0), linear-gradient(#DDD 20px, transparent 0);
    background-repeat: no-repeat;
    background-size: 75px 100px, 55px 56px, 160px 30px, 260px 20px, 290px 56px;
    background-position: 0% 0, 0 0, 70px 5px, 70px 38px, 0px 66px;
    box-sizing: border-box;
    animation: animloader 1s linear infinite;
  }
  
  @keyframes animloader {
    0% {
      background-position: 0% 0, 0 0, 70px 5px, 70px 38px, 0px 66px;
    }
    100% {
      background-position: 150% 0, 0 0, 70px 5px, 70px 38px, 0px 66px;
    }
  }