.fileTypeContainer {
    min-width: 230px;
    width: 15%;
    margin: 10px;
    padding: 10px;
    border-radius: 10px;
    background-color: #e5e7eb;
}

.photoTypeFile  p, .documentTypeFile p{
    margin: 0;
}

.photoTypeFileContainer,.documentTypeFileContainer {
    position: relative;
  }
  
  .photoTypeFile ,.documentTypeFile{
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 10px;
  }
  
  .photoIcon,.fileIcon {
    font-size: 24px; 
    margin-right: 10px; 
  }
  
  .photoTypeFileContainer  .fileInput,.documentTypeFileContainer  .fileInput {
    position: absolute;
    bottom: 50%;
    left: 50%;
    transform: translate(-50%,50%);
    opacity: 0;
  
  }
  