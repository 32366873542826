.loginContainer {
    background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url('../../../Assests/background/bot.png');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    background-attachment: fixed;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: scroll;
}

.loginContainerBox {
    max-width: 600px;
    width: 80%;
}

.loginContainerLogo {
    width: 100px;
    border-radius: 10px
}

.loginContainerTitle {
    color: white;
    text-align: center;
    font-size: 24px;
}

.loginDetailsContainer {
    background-color: rgba(226, 243, 251, 0.8);
    padding: 20px;
    border-radius: 10px;
    /* width: 100%; */
}

.loginDetails {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.loginFrom {
    width: 80%;
}

.loginFormInput {
    width: 90%;
    padding: 8px;
    border: none;
    border-radius: 5px;
    outline: none;
}

.loginFromBtn {
    padding: 10px 20px;
    background: linear-gradient(90deg, rgba(56, 172, 236, 1) 0%, rgba(226, 243, 251, 1) 73%);
    border: none;
    border-radius: 5px;
    cursor: pointer;
    width: 93%;
    color: #38ACEC;
    font-weight: bold;
    font-size: 16px;
}

.animated-image {
    animation: moveRightLeft 5s infinite alternate;
}

@keyframes moveRightLeft {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(5px);
    }
}


@media (max-width: 600px) {
    .animated-image {
        display: none;
    }

    .loginFrom {
        width: 100%;
    }
}