
.viewDetailsContainer{
    background-color:white;
    flex-grow:1;
    padding: 30px;
    border: #38ACEC solid;
    width: 100%;
}

.viewDetailsContainerImg{
    border: #38ACEC solid 2px;
    height: 100px;
    width: 100px;
    border-radius: 100%;
    /* box-shadow: rgba(0, 0, 0, 0.1) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px; */
}

.viewDetailsContainerImg img{
    width: 100%;
    height: 100%;
    border-radius: 100%;
    object-fit: cover;
    object-position: top;
    overflow: hidden;
    

}

.viewDetailsContainerName{
    margin: 20px 0;
    font-weight: bold;
}