.home{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    flex-grow: 1;
    flex: 1 1 0%;
}

.homeText{
    font-size: 1.125rem;
    line-height: 1.75rem;
    font-weight: 700;
}