.channelDetailsPageOptionsContainer{
    padding: 5px 20px;
    background-color: #38ACEC;
}

.channelDetailsPageOptions{
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom:20px;
    color: white;
    font-weight: 600;
    cursor: pointer;
    padding: 0 8px;
    border-radius: 5px;
    
}
.channelDetailsPageOptions:hover, .active{
    background-color: #e2f3fb;
    color:black
}

.channelDetailsPageOptions span{
    font-size: 20px;
}
