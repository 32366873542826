.layoutContainer {
    height: 100vh;
    overflow: hidden;
    display: flex;
    flex: 1 1 0%;
}

.layoutContainerOutlet {
    display: flex;
    flex-direction: column;
    flex: 1 1 0%;
    height: 100%;
    width: 100%
}