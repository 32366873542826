.chatboxFooter {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 2.5rem;
    padding: 1.25rem;
    background-color: #e2f3fb;
    color: #1f2937;
    position: sticky;
    bottom: 0px;
    left: 0px;
    right: 0px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.chatboxFooterAttachments {
    display: flex;
    align-items: center;
    gap: 1.25rem;
}

.chatboxFooterTextArea {
    flex: 1 1 0%;
    outline: none;
    outline-offset: 2px;
    background-color: transparent;
    border:none;
}

.chatboxFooterSendMsgBtn {
    font-size: 1.25rem;
    line-height: 1.75rem;
    cursor: pointer;
    border:none
}