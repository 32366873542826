.ChatBoxHeaderContainer {
    display: flex;
    justify-content: space-between;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    background-color: #e2f3fb;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
}

.ChatBoxHeaderUser {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    cursor: pointer;
}

.ChatBoxHeaderUser img {
    width: 3rem;
    height: 3rem;
    object-fit: cover;
    object-position: top;
    border-radius: 100%;
}

.ChatBoxHeaderUser p {
    color: #1f2937;
    font-weight: 600;
}

.ChatBoxHeaderOptions {
    display: flex;
    align-items: center;
    gap: 2.5rem;
    position: relative;
}

.ChatBoxHeadertextSearch {
    background-color: #cdd8e7;
    display: flex;
    align-items: center;
    gap: 1rem;
    position: absolute;
    top: 40px;
    right: 0px;
    z-index: 100;
    padding:10px 20px;
    border-radius: 10px;
}

.ChatBoxHeadertextSearch input{
    border: none;
    outline: none;
    padding:10px;
    border-radius: 5px;
}

.ChatBoxHeadertextSearchIcon{
    font-size: 20px;
    cursor: pointer;
}

.ChatBoxHeadertextSearchBox{
    position: relative;
    background-color:white ;
    width:250px;
    border-radius:5px;

}